<!-- <h1 matDialogTitle>Confirm</h1>
<div mat-dialog-content>{{confirmMessage}}</div>
<div mat-dialog-actions class="pt-24">
    <button mat-raised-button class="mat-accent mr-16" (click)="dialogRef.close(true)">Confirm</button>
    <button mat-button (click)="dialogRef.close(false)">Cancel</button>
</div> -->

<div mat-dialog-title class="mat-dialog-header" fxLayout="row">
    <h1>Cancel</h1>
    <button mat-icon-button (click)="dialogRef.close(false)" aria-label="Close dialog">
        <mat-icon class="mat-Close">cancel</mat-icon>
    </button>
</div>

<div class="mat-dialog-content">{{confirmMessage}}</div>

<div class="mat-dialog-footer">
    <div class="mat-btn-footer" fxLayout="row">
        <button mat-raised-button (click)="dialogRef.close(true)" color="accent">Confirm</button>
        <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
    </div>
</div>