export class RoleInfo {
    isAdmin: boolean = false;
    ClientViewAccess: boolean = false;
    ClientAddAccess: boolean = false;
    ClientEditAccess: boolean = false;
    ClientDeleteAccess: boolean = false;
    MatterViewAccess: boolean = false;
    MatterAddAccess: boolean = false;
    MatterEditAccess: boolean = false;
    MatterDeleteAccess: boolean = false;
    CaseViewAccess: boolean = false;
    CaseAddAccess: boolean = false;
    CaseEditAccess: boolean = false;
    CaseDeleteAccess: boolean = false;
    DocketViewAccess: boolean = false;
    DocketAddAccess: boolean = false;
    DocketEditAccess: boolean = false;
    DocketDeleteAccess: boolean = false;
    EventViewAccess: boolean = false;
    EventAddAccess: boolean = false;
    EventEditAccess: boolean = false;
    EventDeleteAccess: boolean = false;
    SysAdminViewAccess: boolean = false;
    SysAdminAddAccess: boolean = false;
    SysAdminEditAccess: boolean = false;
    SysAdminDeleteAccess: boolean = false;
    Judge_Party_ViewAccess: boolean = false;
    Judge_Party_AddAccess: boolean = false;
    Judge_Party_EditAccess: boolean = false;
    Judge_Party_DeleteAccess: boolean = false;
    CascadingTextTool: boolean = false;
    UseReports: boolean = false;
    AccessAutoReportScheduling: boolean = false;
    UseAutoDocket: boolean = false;
    EmailCaseTeam: boolean = false;
    SystemAdministrator: boolean = false;
    EditAccess: boolean = true;
    DeleteAccess: boolean = true;
    UsePowerBIReports: boolean = false;
    DocketDirectEnabled: boolean = false;
    UseQuickDates: boolean = false;
    UseCourtRulesReport: boolean = false;
    Attorney_ViewAccess: boolean = false;
    Attorney_AddAccess: boolean = false;
    Attorney_EditAccess: boolean = false;
    Attorney_DeleteAccess: boolean = false;
    DashBoardAccess: boolean = false;
    CaseManagementAccess: boolean = false;
    CalenderSelfAccess: boolean = false;
}
