import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;
    private roleAccess: any;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();


        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                // Mark for check
                this.CheckingPermissions();
                this._changeDetectorRef.markForCheck();
            });

    }

    CheckingPermissions(){
        var _allProducts = sessionStorage.getItem('allProducts');
        if (_allProducts) {
            var allClients = JSON.parse(_allProducts);
            for (let i = 0; i < allClients.length; i++) {
                if (allClients[i]["isSubscribed"]) {
                    this._fuseNavigationService.updateNavigationItem(allClients[i]["clientId"].toLowerCase(), {
                        url: allClients[i]["clientUri"],
                        hidden: false
                    });
                }
                else {
                    this._fuseNavigationService.updateNavigationItem(allClients[i]["clientId"].toLowerCase(), {
                        url: allClients[i]["clientUri"],
                        hidden: true
                    });
                }
            }
        }

        var _userProducts = sessionStorage.getItem('roleAccessPermissions');
        if (_userProducts) {
            this.roleAccess = JSON.parse(_userProducts);
            this._fuseNavigationService.updateNavigationItem("dashboard", {
                hidden: !this.roleAccess.DashBoardAccess
            });
            this._fuseNavigationService.updateNavigationItem("case-management", {
                hidden: !this.roleAccess.CaseManagementAccess
            });
            this._fuseNavigationService.updateNavigationItem("eDocketsCalendar", {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem("Administrators", {
                hidden: !this.roleAccess.SystemAdministrator
            });
            this._fuseNavigationService.updateNavigationItem("autodocket", {
                hidden: !this.roleAccess.UseAutoDocket
            });
            this._fuseNavigationService.updateNavigationItem("MilanaQuickDates", {
                hidden: !this.roleAccess.UseQuickDates
            });
            this._fuseNavigationService.updateNavigationItem("cr-management", {
                hidden: !this.roleAccess.UseCourtRulesReport
            });
            this._fuseNavigationService.updateNavigationItem("docketalerts", {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem("applications", {
                hidden: false
            });
        }
    }
}
