import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { ALNIdentityAuthService } from 'app/core/auth/aln-identity-auth.service';
import { User } from 'app/core/models/user';
import { HttpClient } from '@angular/common/http';
import { AdminApiService } from '@fuse/services/admin-api.service';
import { CustomMessageDto } from 'app/core/models/custom-message-dto';
import { CustomMessagesGuids } from 'app/core/models/custom-messages-res-dto';
import { SignalrService } from '@fuse/services/signalr.service';
import { SafeHtml } from '@angular/platform-browser';
import { environment as env } from '../../../environments/environment';
import * as DOMPurify from 'dompurify';

@Component({
    selector   : 'fuse-shortcuts',
    templateUrl: './shortcuts.component.html',
    styleUrls  : ['./shortcuts.component.scss']
})
export class FuseShortcutsComponent implements OnInit, AfterViewInit, OnDestroy
{
    public bannerContentCompanyList : SafeHtml[] = [];
    public bannerContentGlobalList : SafeHtml[] = [];
    shortcutItems: any[];
    navigationItems: any[];
    filteredNavigationItems: any[];
    searching: boolean;
    mobileShortcutsPanelActive: boolean;
    currentUser: User;
    @Input()
    navigation: any;

    @ViewChild('searchInput')
    searchInputField;

    @ViewChild('shortcuts')
    shortcutsEl: ElementRef;

    notifications: CustomMessageDto[];
    unreadNotificationsCount: number = 0;
    subscription: any;
    matBadgeColor: string = "accent";
    allCustomMessages: CustomMessageDto[];

    unreadSuccessfully: boolean = false;
    // Private
    private _unsubscribeAll: Subject<any>;
    private hasMessagesInitiallyLoaded: boolean = false;
    private lastFutureStartTimeOffset: number = -1;
    private nextEndTimeTimeOffset: number = -1;
    private intervalIdForIntialLoadOfCustomMessages: NodeJS.Timeout;
    private intervalIdForCustomMessages: NodeJS.Timeout;
    private customMessageIntervalInSeconds: number = 0;
    private lastCheckedUtcDate: Date;

    /**
     * Constructor
     *
     * @param {CookieService} _cookieService
     * @param {FuseMatchMediaService} _fuseMatchMediaService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {MediaObserver} _mediaObserver
     * @param {Renderer2} _renderer
     */
    constructor(
        private _cookieService: CookieService,
        private _fuseMatchMediaService: FuseMatchMediaService,
        private _fuseNavigationService: FuseNavigationService,
        private _mediaObserver: MediaObserver,
        private _renderer: Renderer2,
        private authenticationService: ALNIdentityAuthService,
        private httpClient: HttpClient,
        private adminApiService: AdminApiService,
        public signalRService: SignalrService
    )
    {
        // Set the defaults
        this.shortcutItems = [];
        this.searching = false;
        this.mobileShortcutsPanelActive = false;
        this.customMessageIntervalInSeconds = env.customMessageIntervalInSeconds;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.currentUser = this.authenticationService.user;
        // Get the navigation items and flatten them
        this.filteredNavigationItems = this.navigationItems = this._fuseNavigationService.getFlatNavigation(this.navigation);
        
        if(!this.lastCheckedUtcDate)
        {
            //choose any date before the tables/rows were created for this
            this.lastCheckedUtcDate = new Date('2023-01-01T01:00:00');
        }
        // Load BannerText and Notifications after token
        this.subscription = this.authenticationService.getTokenChangeEvent().subscribe(item => {
            this.checkAndLoadCustomMessages();
            if(this.shouldUseSignalR()){
                this.signalRService.connect();
            }
        });

        if(this.shouldUseSignalR())
        {
            if(this.currentUser && this.currentUser.bearerToken){
                this.loadCustomMessages();
                this.signalRService.connect();
            }

            // Load BannerText and Notifications after Custom Messages list change
            this.subscription = this.signalRService.getMessagesListEvent().subscribe((customMessages: CustomMessageDto[]) => {
                if(customMessages)
                {
                    this.loadMessages(customMessages);
                }
                else
                {
                    this.loadCustomMessages();
                }
            });
        }
        else
        {
            this.intervalIdForCustomMessages = setInterval(() => {
                this.checkAndLoadCustomMessages();
            }, this.customMessageIntervalInSeconds * 1000);

            this.hasMessagesInitiallyLoaded = false;
            this.intervalIdForIntialLoadOfCustomMessages = setInterval(() => {
                if(this.hasMessagesInitiallyLoaded){
                    if(this.intervalIdForIntialLoadOfCustomMessages){
                        clearInterval(this.intervalIdForIntialLoadOfCustomMessages);
                    }
                }
                else{
                    this.checkAndLoadCustomMessages();
                }
            }, 2000);
        }
        
        if ( this._cookieService.check('FUSE2.shortcuts') )
        {
            this.shortcutItems = JSON.parse(this._cookieService.get('FUSE2.shortcuts'));
        }
        else
        {
            // User's shortcut items
            this.shortcutItems = [
                {
                    title: 'Calendar',
                    type : 'item',
                    icon : 'today',
                    url  : '/appgateway/calendar'
                },
                {
                    title: 'Mail',
                    type : 'item',
                    icon : 'email',
                    url  : '/appgateway/mail',
                },
                {
                    title: 'Contacts',
                    type : 'item',
                    icon : 'account_box',
                    url  : '/appgateway/contacts'
                },
                // {
                //     title: 'To-Do',
                //     type : 'item',
                //     icon : 'check_box',
                //     url  : '/appgateway/todo'
                // }
            ];
        }

    }
    checkAccess(item: any) {
        if(item.title == 'Mail'){
            this.currentUser = this.authenticationService.user;
            window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/mail`;
        }
        if(item.title == 'Contacts'){
            this.currentUser = this.authenticationService.user;
            window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/contacts`;
        }
        if(item.title == 'Calendar'){
            this.currentUser = this.authenticationService.user;
            window.location.href = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/calendar`;
        }
    }
    ngAfterViewInit(): void
    {
        // Subscribe to media changes
        this._fuseMatchMediaService.onMediaChange
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                if ( this._mediaObserver.isActive('gt-sm') )
                {
                    this.hideMobileShortcutsPanel();
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        if (this.intervalIdForCustomMessages) {
            clearInterval(this.intervalIdForCustomMessages);
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Search
     *
     * @param event
     */
    search(event): void
    {
        const value = event.target.value.toLowerCase();

        if ( value === '' )
        {
            this.searching = false;
            this.filteredNavigationItems = this.navigationItems;

            return;
        }

        this.searching = true;

        this.filteredNavigationItems = this.navigationItems.filter((navigationItem) => {
            return navigationItem.title.toLowerCase().includes(value);
        });
    }

    /**
     * Toggle shortcut
     *
     * @param event
     * @param itemToToggle
     */
    toggleShortcut(event, itemToToggle): void
    {
        event.stopPropagation();

        for ( let i = 0; i < this.shortcutItems.length; i++ )
        {
            if ( this.shortcutItems[i].url === itemToToggle.url )
            {
                this.shortcutItems.splice(i, 1);

                // Save to the cookies
                this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));

                return;
            }
        }

        this.shortcutItems.push(itemToToggle);

        // Save to the cookies
        this._cookieService.set('FUSE2.shortcuts', JSON.stringify(this.shortcutItems));
    }

    /**
     * Is in shortcuts?
     *
     * @param navigationItem
     * @returns {any}
     */
    isInShortcuts(navigationItem): any
    {
        return this.shortcutItems.find(item => {
            return item.url === navigationItem.url;
        });
    }

    /**
     * On menu open
     */
    onMenuOpen(): void
    {
        setTimeout(() => {
            this.searchInputField.nativeElement.focus();
        });
    }

    /**
     * Show mobile shortcuts
     */
    showMobileShortcutsPanel(): void
    {
        this.mobileShortcutsPanelActive = true;
        this._renderer.addClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    /**
     * Hide mobile shortcuts
     */
    hideMobileShortcutsPanel(): void
    {
        this.mobileShortcutsPanelActive = false;
        this._renderer.removeClass(this.shortcutsEl.nativeElement, 'show-mobile-panel');
    }

    loadCustomMessages() {
        this.adminApiService.getCustomMessages().subscribe(result=> {
            this.loadMessages(result.content);
        }, 
        error => {});
    }
    checkForNewCustomMessagesAndThenCallLoad() {
        let lastCheckedDateUtcAsString: string = this.lastCheckedUtcDate.toISOString();
        this.adminApiService.checkForNewCustomMessages(lastCheckedDateUtcAsString).subscribe(result=> {
            if(result && result.content)
            {
                this.loadCustomMessages();
                this.lastCheckedUtcDate = this.getUtcDate();
            }
        }, 
        error => {});
    } 
    
    markCustomMessagesAsRead(isNotification: boolean, customMessageGuid: string = "") {
        if(this.unreadNotificationsCount === 0 && isNotification){
            return;
        }

        let customMessageGuids = new CustomMessagesGuids();
        customMessageGuids.customMessageGuids = customMessageGuid;
        if(isNotification){
            let notificationsArr: Array<string> = this.notifications.filter(x=> !x.isRead).map(y=>y.customMessageId);
            customMessageGuids.customMessageGuids = notificationsArr.toString();
        }

        this.unreadSuccessfully = false;
        this.adminApiService.markCustomMessagesAsRead(customMessageGuids).subscribe(result=> {
            if (result.is_success && isNotification) {
                this.unreadSuccessfully = true;
            }
        }, 
        error => {});
    }

    notificationMenuClosed() {
        if(this.unreadSuccessfully) {
            this.notifications.forEach(function(el){el.isRead = true;});
            this.unreadNotificationsCount = 0;
            this.unreadSuccessfully = false;
        }
    }
    private updateBannerFromCustomMessages(customMessages: CustomMessageDto[], markAsRead: boolean) {
        let bannerData = customMessages.filter(a => a.isBannerText);
        this.bannerContentCompanyList = [];
        this.bannerContentGlobalList = [];
        if (bannerData && bannerData.length > 0) {
            this.bannerContentCompanyList = bannerData.filter(b => !b.aderantMessage).map(b => b.customMessageSafeHtml);
            this.bannerContentGlobalList = bannerData.filter(b => b.aderantMessage).map(b => b.customMessageSafeHtml);

            if(markAsRead){
                bannerData.forEach(b => {
                    if(!b.isRead) {
                        this.markCustomMessagesAsRead(false, b.customMessageId);
                    }
                });
            }
            
        }
    }

    private loadMessages(customMessages: CustomMessageDto[]){
        this.allCustomMessages = customMessages;
        if (customMessages && customMessages.length > 0) {
            this.allCustomMessages.forEach(m => m.customMessageSafeHtml = this.sanitizeHtml(m.customMessageText));

            this.updateBannerFromCustomMessages(this.allCustomMessages, true);

            let notificationsData = customMessages.filter(a => !a.isBannerText);
            if (notificationsData && notificationsData.length > 0) {
                this.notifications = notificationsData;
                this.unreadNotificationsCount = this.notifications.filter(a => !a.isRead).length;
                if(this.notifications.filter(a => !a.isRead && a.aderantMessage).length !== 0){
                    this.matBadgeColor = "warn";
                }
            }
        }

        // Get next future notification startdate
        this.getNextFutureStartDate();
        this.handleNextFutureEndDate();
    }

    getNextFutureStartDate() {
        this.adminApiService.getNextFutureStartDate().subscribe(result=> {
            if(result.is_success && result.content) {
                // Set the trigger date and time
                let utcStartDateTime = this.getTimeUsingLocalTimeZoneOffset(result.content);
                let delay: number = 0;
                if(this.lastFutureStartTimeOffset != utcStartDateTime){
                    this.lastFutureStartTimeOffset = utcStartDateTime;
                    // Calculate the delay in milliseconds
                    delay = utcStartDateTime - this.getUtcDate().getTime();
                }

                if(delay > 0){
                    // Schedule the task using setTimeout
                    setTimeout(() => {
                        // make the function call you are making now to get the custom messages
                        this.loadCustomMessages();
                    }, delay);
                }
            }
        }, 
        error => {});
    } 

    handleNextFutureEndDate() {
        let endDates = this.sortByEndDate();
        if(endDates && endDates.length > 0){
            let msg = endDates[0];

            let utcEndDateTime = this.getTimeUsingLocalTimeZoneOffset(msg.utcEndDate); 
            let delay: number = -1;
            if(this.nextEndTimeTimeOffset != utcEndDateTime){
                this.nextEndTimeTimeOffset = utcEndDateTime;
                // Calculate the delay in milliseconds        
                delay = utcEndDateTime - this.getUtcDate().getTime();
            }
            
            //The delay can be negative if the call was made just after it ended
            if(delay != -1) {
                // Schedule the task using setTimeout
                setTimeout(() => {
                    // remove the entry from the notifications
                    this.removeNotificationThatIsEnding(msg.customMessageId);
                }, delay);
            }
            
        }
    }

    removeNotificationThatIsEnding(customMessageId: string) {
        // Remove from all custom messages
        this.allCustomMessages.forEach((value,index) => {
            if(value.customMessageId == customMessageId) {
                this.allCustomMessages.splice(index, 1);
            }
        });

        this.updateBannerFromCustomMessages(this.allCustomMessages, false);

        // Check notifications popup and remove notification if match 
        this.notifications.forEach((value,index) => {
            if(value.customMessageId == customMessageId) {
                this.notifications.splice(index, 1);
            }
        });
        
        this.unreadNotificationsCount = this.notifications.filter(a => !a.isRead).length

        // call method again to fetch next latest end date
        this.handleNextFutureEndDate();
    } 
    
    private sortByEndDate() {
        return  this.allCustomMessages.sort((a: CustomMessageDto, b: CustomMessageDto) => {
            return this.getTimeUsingLocalTimeZoneOffset(a.utcEndDate) - this.getTimeUsingLocalTimeZoneOffset(b.utcEndDate) ;
        });
    }

    private getUtcDate(){
        let localDate = new Date();
        let utcDate = new Date(localDate.getTime() + localDate.getTimezoneOffset()*60000);
        return utcDate;
    }

    // Get Date time using localDateOffSet 
    // When create new date from string/date, js consider that date as utc and convert that into local timezone date by add offset
    // So to avoid that and for actual date, need to add LocalTimeZoneOffset to new date
    private getTimeUsingLocalTimeZoneOffset(date: string){
        let localDate = new Date();

        // Calculate local date offset in milliseconds
        let localDateOffSet = localDate.getTimezoneOffset()*60000;

        // Get date time in milliseconds
        let time = new Date(date).getTime() + localDateOffSet; 

        return time;
    }

    private sanitizeHtml(html: string): SafeHtml {
        return DOMPurify.sanitize(html);  
    }
    private shouldUseSignalR(): boolean {
        return !this.customMessageIntervalInSeconds || this.customMessageIntervalInSeconds < 1;
    }
    private checkAndLoadCustomMessages() {
        this.signalRService
        .getToken()
        .subscribe((accessToken: string) => {
            if(accessToken){
                this.checkForNewCustomMessagesAndThenCallLoad();
                this.hasMessagesInitiallyLoaded = true;
            }
        });
    }
}

//todo: move this to separate file
export class BannerTextObject
{
    startDate: string;
    endDate: string;
    bannerText: string;
}