import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WebApiService } from './web-api.service';
import { CheckNewCustomMessagesResDto, CustomMessagesGuids, CustomMessagesResDto } from 'app/core/models/custom-messages-res-dto';
import { StartDateResDto } from 'app/core/models/start-date-res-dto';
@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
 
  constructor(private _webApiService: WebApiService) { }

    // begin custom messages    
    getCustomMessages(): Observable<CustomMessagesResDto> {
      return this._webApiService.getAdmin(adminApiRoutes.getCustomMessages());
    }    
    checkForNewCustomMessages(lastCheckedDateUtcAsString: string): Observable<CheckNewCustomMessagesResDto> {
      return this._webApiService.getAdmin(adminApiRoutes.checkForNewCustomMessages(lastCheckedDateUtcAsString));
    }    
    markCustomMessagesAsRead(customMessageGuids: CustomMessagesGuids): Observable<CustomMessagesResDto> {
      return this._webApiService.updateAdminWithBody(adminApiRoutes.markCustomMessagesAsRead(), customMessageGuids);
    }
    getNextFutureStartDate(): Observable<StartDateResDto> {
      return this._webApiService.getAdmin(adminApiRoutes.getNextFutureStartDate());
    }  
    // end custom messages
}
const adminApiRoutes = {

  // begin custom-messages 
  getCustomMessages: () => `custom-messages?allCustomMessages=false`,
  checkForNewCustomMessages: (lastCheckedDateUtcAsString: string) => `custom-messages/check-new/${lastCheckedDateUtcAsString}`,
  markCustomMessagesAsRead: () => `custom-message-user-mappings`,
  getNextFutureStartDate: () => `custom-messages/future-startdate`,
  // end custom-messages
};