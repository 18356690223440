import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FuseSharedModule } from '@fuse/shared.module';
import { MainRoutingModule } from './main-routing.module';
import { MainLayoutComponent } from './main-layout/main-layout.component';
import { FuseSidebarModule, FuseThemeOptionsModule, FuseProgressBarModule, FuseWidgetModule } from '@fuse/components';
import { LayoutModule } from 'app/layout/layout.module';
import { ALNIdentityAuthGuard } from './guards/aln-identity-auth.guard';
import { AppGatewayComponent } from '../core/appgateway.component';
import { IdleDialogComponent } from './Idle/idle-dialog.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [
        MainLayoutComponent,
        AppGatewayComponent,
        IdleDialogComponent
    ],
    imports: [
        MainRoutingModule,
        // Fuse modules
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,
        FuseProgressBarModule,
        FuseWidgetModule,
        LayoutModule,
        MatButtonModule,
        MatIconModule,
    ],
    providers: [
        ALNIdentityAuthGuard,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class MainModule { }
