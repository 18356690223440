export const environment = {
    production: true,
    hmr: false,
    apiUrl: 'https://api.edockets.cdr.alncorp.com/api',
    centralAppBaseUri: 'https://apps.cdr.alncorp.com',
    efilingBaseUri: 'http://efiling.cdr.alncorp.com',
    administrationUri: 'https://admin.cdr.alncorp.com/home',
    myProfileUri: 'https://identity.cdr.alncorp.com/user',
    changePassword: 'https://identity.cdr.alncorp.com/manage/changepassword',
    stsUrl: 'https://identity.cdr.alncorp.com/',
    clientId: 'eDockets',
    redirectRoute: '/appgateway/home',
    clientUrl: 'https://alncloud.cdr.alncorp.com',
    silentRenewRoute: 'silent-renew.html',
    logConsoleDebugActive: true,
    centralAPIBaseUri: 'https://api.apps.cdr.alncorp.com',
    postLoginUrl: '/appgateway/home',
    idleTimeOut:'21600',
    messageTimeOut:'60',
    adminApiUrl:'https://edadminapi.cdr.alncorp.com/api/',
    signalRApiUrl: 'https://signalr.api.cdr.alncorp.com/milana',
    customMessageIntervalInSeconds: 30,
    netDocsRedirectUrlPath: 'cm/netdocuments'
};
