import { ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { FuseNavigationItem } from '@fuse/types';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { SharedService } from '@fuse/services/shared.service';
import { User } from 'app/core/models/user';
import { ALNIdentityAuthService } from 'app/core/auth/aln-identity-auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';

@Component({
    selector: 'fuse-nav-vertical-item',
    templateUrl: './item.component.html',
    styleUrls: ['./item.component.scss']
})
export class FuseNavVerticalItemComponent implements OnInit, OnDestroy {
    @HostBinding('class')
    classes = 'nav-item';
    @Input()
    item: FuseNavigationItem;
    currentUser: User;
    confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
    private _unsubscribeAll: Subject<any>;
    listofProducts: Array<any> = [];

    /**
     * Constructor
     */

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService,
        public _data: SharedService,
        private authenticationService: ALNIdentityAuthService,
        private router: Router,
        public dialog: MatDialog
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.checkAccessForeDockets();

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

    }

    checkAccessForeDockets() {
        var _allProducts = sessionStorage.getItem('allProducts');
        if (_allProducts) {
            var allClients = JSON.parse(_allProducts);
            var edocketProd = allClients.filter(x => x.clientId == 'eDockets');
            if (edocketProd.length > 0 && !edocketProd[0].isSubscribed) {
                this.router.events.subscribe((e) => {
                    if (e instanceof NavigationEnd) {
                        if (e.url && (e.url.includes('/case-management')
                            || e.url.includes('/dashboard')
                            || e.url.includes('eDockets')
                            || e.url.includes('admin-management')
                            || e.url.includes('autodocket')
                            || e.url.includes('docketalerts'))) {
                            this._data.sendMessage(true);
                            return;
                        }
                    }
                });
            }
        }
    }

    checkAccess() {
        let isFormChanged = sessionStorage.getItem("isFormChanged");
        if (!!isFormChanged) {
            this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, { disableClose: false });
            this.confirmDialogRef.componentInstance.confirmMessage = 'You have unsaved changes.  Do you wish to continue WITHOUT saving your changes?';;
            this.confirmDialogRef.afterClosed().subscribe(result => {
                if (result) {
                    this.formvalidation();
                    sessionStorage.removeItem("isFormChanged");
                } else {
                    let element = document.getElementById('Item-case-management');
                    element.classList.add('accent');
                }
            })
        }
        else {
            this.formvalidation();
        }
    }

    formvalidation() {
        this.currentUser = this.authenticationService.user;
        if (this.item.code == 'mail') {
            this.item.url = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/mail`;
        }
        else if (this.item.code == 'calendar') {
            this.item.url = `${environment.centralAppBaseUri}/apps/appgateway/${this.currentUser.userId}/calendar`;
        }
        else {
            if (!this.item.openInNewTab) {
                this._data.sendMessage(this.verifyProductAccess(this.item.code));
            }
        }
        this.router.navigateByUrl(this.item.url);
        if (this.item.url === "/") {
            // Set the main navigation as our current navigation
            this._fuseNavigationService.setCurrentNavigation('main');
        }

        if (this.item.url === "/docketalerts") {
            // Set the docketAlert navigation as our current navigation
            this._fuseNavigationService.setCurrentNavigation('docketAlerts');
        }

        let element = document.getElementsByClassName("shell-app-link");
        if (element.length != 0) {
            for (var i = 0; i <= element.length - 1; i++) {
                if (element[i].classList.contains('accent')) {
                    element[i].classList.remove('accent');
                }
            }
            let appLink = document.getElementById("Item-" + this.item.id);
            if (appLink) {
                appLink.classList.add('accent');
            }
        }
    }
    verifyProductAccess(productId): any {
        this.listofProducts = [];
        var _user = sessionStorage.getItem('userProducts');
        if (_user) {
            this.listofProducts = JSON.parse(_user);
            if (this.listofProducts.filter(x => x.clientCode == productId).length == 0) {
                return true;
            }
        }
        return false;
    }

    isActive(url): boolean {
        return window.location.href.includes(url);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
